import React, { memo, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import {
  FormButton,
  Box,
  Card,
  Text,
  FormInner,
  Label,
  theme,
  FormGroup,
  FormControlFeedback,
  AdaptedTextarea,
  AdaptedRadio,
} from "@cyberalarm/ui";
import styled from "styled-components";
import build from "redux-object";
import { toast } from "react-toastify";
import { api } from "@cyberalarm/common";
import { form } from "@cyberalarm/common";
import qs from "qs";
import useSWR from "swr";
import normalizer from "json-api-normalizer";
import { newTicketSchema } from "../support/schema";
import { ResultsGrid } from "./results-grid";
import { submitLabel, descriptionLabel } from "shared-schema";
import { FormattedMessage } from "react-intl";
import { i18n } from "./schema";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FlexCard = styled(Card)`
  min-width: 544px;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    min-width: 400px;
  }
`;

const RadioFlexBox = styled(FlexBox)`
  margin-bottom: 0;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin-bottom: 8px;
  }
`;

interface ModalProps {
  close: () => void;
  checkResult?: CheckResult;
  domainID: string;
}

interface CreateTicketProps {
  type: string;
  requestDescription: string;
}

const validator = form.validateForm({ schema: newTicketSchema });

const Modal = ({ close, domainID }: ModalProps) => {
  const [selectedCheckResultIds, setSelectedCheckResultIds] = useState<
    string[]
  >([]);
  const [checkResults, setCheckResults] = useState<CheckResult[]>([]);

  const url = `domains/${domainID}/results/failedresults?${qs.stringify({
    limit: 5000,
  })}`;

  const { data, isValidating } = useSWR(url, () => api(`${url}`));

  const onSubmit = async (values: CreateTicketProps) => {
    try {
      const ids =
        values.type === "specific"
          ? selectedCheckResultIds.join(",")
          : checkResults.map((r: { id: string }) => r.id).join(",");
      await api("support/create_ticket", {
        method: "POST",
        body: {
          rootDomainId: domainID,
          checkResultIds: ids,
          requestDescription: values.requestDescription,
          type: "check_result",
        },
      });
      toast.success("Ticket created successfully");
      close();
    } catch (error) {
      toast.error("Failed to create ticket! Contact System Administrator.");
    }
  };

  useEffect(() => {
    if (data !== undefined && !isValidating) {
      const normalizedData = normalizer(data);
      const builtData = build(normalizedData, "checkResult");
      const filteredResults = builtData.filter(
        (r: CheckResult) => r.supportTicket === null
      );
      setCheckResults(filteredResults);
    }
  }, [data, isValidating]);

  return (
    <>
      <FlexCard>
        <Box marginX="auto" marginY={6} flex={1} minHeight="68px">
          <Box alignItems="center" marginX="auto">
            <Label>
              <Text fontSize="24px" fontFamily="title">
                <FormattedMessage {...i18n.createTicketLabel} />
              </Text>
            </Label>
          </Box>

          <Form
            onSubmit={onSubmit}
            validate={validator}
            initialValues={{ type: "all", requestDescription: "" }}
          >
            {({ handleSubmit, values }) => {
              return (
                <FormInner onSubmit={handleSubmit}>
                  <Box marginX="auto" marginTop="16px">
                    <FlexBox mt={3} width="400px" marginX="auto" paddingX="8px">
                      <FormGroup>
                        <Label>
                          <FormattedMessage {...descriptionLabel} /> *
                        </Label>
                        <Field
                          component={AdaptedTextarea}
                          id="requestDescription"
                          name="requestDescription"
                          label="Description"
                        />
                        <FormControlFeedback name="requestDescription" />
                      </FormGroup>
                    </FlexBox>
                    <Box flexDirection="column" marginX="auto">
                      <Box marginY="16px" marginX="auto">
                        <FormButton
                          type="submit"
                          variant="primary"
                          disabled={
                            (values.type === "all" &&
                              checkResults.length === 0) ||
                            (values.type === "specific" &&
                              selectedCheckResultIds.length === 0)
                          }
                        >
                          <FormattedMessage {...submitLabel} />
                        </FormButton>
                      </Box>

                      <Box marginY="16px">
                        <Text style={{ textDecoration: "underline" }}>
                          Select alarms and/or warnings ( Current selection :{" "}
                          {values.type === "all"
                            ? checkResults.length
                            : selectedCheckResultIds.length}{" "}
                          )
                        </Text>
                        <RadioFlexBox>
                          <Field
                            component={AdaptedRadio}
                            name="type"
                            label="All alarms and warnings"
                            type="radio"
                            value="all"
                          />
                        </RadioFlexBox>
                        <RadioFlexBox>
                          <Field
                            component={AdaptedRadio}
                            name="type"
                            label="Specific alarms and warnings"
                            type="radio"
                            value="specific"
                          />
                        </RadioFlexBox>
                      </Box>
                    </Box>
                    {values.type === "specific" && checkResults.length > 0 && (
                      <Box
                        mt={3}
                        overflowY="auto"
                        maxHeight="300px"
                        paddingX="8px"
                      >
                        <ResultsGrid
                          results={checkResults}
                          setCheckResultIds={setSelectedCheckResultIds}
                        />
                      </Box>
                    )}

                    {values.type === "specific" && checkResults.length === 0 && (
                      <Box padding={5} marginLeft={5}>
                        <Text>
                          <FormattedMessage {...i18n.noResultsForGrid} />
                        </Text>
                      </Box>
                    )}
                  </Box>
                </FormInner>
              );
            }}
          </Form>
        </Box>
      </FlexCard>
    </>
  );
};

export const CreateTicketModal = memo(Modal);

CreateTicketModal.displayName = "CreateGeneralTicket";
