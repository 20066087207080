import React, {
  useCallback,
  memo,
  useRef,
  useContext,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  Accordion,
  Icon,
  Button,
  Textarea,
  ArrowIconRoot,
  Box,
  Loader,
} from "@cyberalarm/ui";
import { toast } from "react-toastify";
import { Icons, useToggle, api } from "@cyberalarm/common";
import build from "redux-object";
import normalize from "json-api-normalizer";
import { SectionLabel } from "../modal-preview-components";
import { useDomainID } from "utils/use-domain-id";
import { domainSelectors, DomainStateContext } from "store";

import { actions } from "./schema";

import { SupportRequestDetails } from "./support-request-details";

interface SupportRequestProps {
  result: CheckResult | TestResult;
  supportTicket: SupportTicket | undefined;
  setSupportTicket: (ticket: SupportTicket) => void;
}

export const SupportRequest = memo(
  ({ result, supportTicket, setSupportTicket }: SupportRequestProps) => {
    const state = useContext(DomainStateContext);
    const [submitting, setSubmitting] = useState(false);
    const domainID = useDomainID();

    const activeDomain = domainSelectors.getActiveDomain(state, {
      activeDomainID: domainID ?? "",
    });

    const domain = useMemo(
      () =>
        activeDomain &&
        domainID &&
        build({ domain: activeDomain }, "domain", domainID),
      [activeDomain, domainID]
    );

    const inputReference = useRef<null | HTMLTextAreaElement>(null);
    const [isAccordionOpen, toggleAccordion] = useToggle();
    const [buttonEnabled, setButtonEnabled] = useToggle(true);

    const createTicket = useCallback(async () => {
      try {
        setSubmitting(true);
        setButtonEnabled(false);
        const data = await api("support/create_ticket", {
          method: "POST",
          body: {
            rootDomainId: domain.id,
            domain: domain.name,
            check_result_ids: result.id,
            request_description: inputReference.current?.value,
            type: result.status === "warnings" ? "warning" : "alarm",
          },
        });
        const normalizedData = normalize(data);
        const builtTicketata = build(normalizedData, "supportTicket");
        setSubmitting(false);
        setSupportTicket(builtTicketata[0]);
        toast.success("Ticket created successfully");
      } catch (error) {
        setSubmitting(false);
        toast.error("Failed to create ticket! Contact System Administrator.");
      }
    }, [result, setSupportTicket, setButtonEnabled, domain.name, domain.id]);

    return (
      <Box>
        <Accordion
          isOpen={isAccordionOpen}
          onClick={toggleAccordion}
          root={
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
              marginTop={3}
            >
              <SectionLabel>
                {!supportTicket ? (
                  <FormattedMessage {...actions.requestHelp} />
                ) : (
                  <FormattedMessage {...actions.ticketDetails} />
                )}
              </SectionLabel>

              <ArrowIconRoot isOpen={isAccordionOpen}>
                <Icon name={Icons.arrow} />
              </ArrowIconRoot>
            </Box>
          }
        >
          {!supportTicket && !submitting && (
            <Box marginTop={3} flex={1}>
              <Textarea
                valid={true}
                ref={inputReference}
                style={{ fontSize: "16px" }}
              />
              <Button
                onClick={createTicket}
                variant="primary"
                marginTop="20px"
                disabled={!buttonEnabled}
              >
                <FormattedMessage {...actions.createTicket} />
              </Button>
            </Box>
          )}
          {submitting && <Loader />}
          {supportTicket && !submitting && (
            <Box marginTop={3} flex={1}>
              <SupportRequestDetails supportTicket={supportTicket} />
            </Box>
          )}
        </Accordion>
      </Box>
    );
  }
);

SupportRequest.displayName = "SupportRequest";
