import { pathOr } from "ramda";
import { defineMessages } from "react-intl";
import * as yup from "yup";

import { required } from "shared-schema";

export const {
  title,
  goodNews,
  allTests,
  printReport,
  helpMeFix,
} = defineMessages({
  title: {
    defaultMessage:
      "{status, select, failed {Urgent action required - Alarms} warnings {Action required - Warnings} passed {No action required - Secure} other {Urgent action required - Alarms}}",
    id: "dashboardActions.title",
  },
  goodNews: {
    defaultMessage: "Good news, you’re all secure",
    id: "dashboardActions.goodNews",
  },
  allTests: {
    defaultMessage: "Advanced",
    id: "dashboardActions.allTests",
  },
  printReport: {
    defaultMessage: "Print",
    id: "dashboardActions.printReport",
  },
  helpMeFix: {
    defaultMessage: "Help me fix",
    id: "dashboardActions.helpMeFix",
  },
});

export const newTicketSchema = yup.object().shape({
  requestDescription: yup
    .string()
    .ensure()
    .required(required.id),
  checkResultIds: yup.string().required(required.id),
});

export const getFailedCount = pathOr(0, ["meta", "failedCount"]);
export const getPassedCount = pathOr(0, ["meta", "passedCount"]);
export const getWarningsCount = pathOr(0, ["meta", "warningsCount"]);
export const getLastScanAt = pathOr(null, ["meta", "lastScanAt"]);
