import React, { useContext, memo, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Text,
  Box,
  theme,
  TitleBar,
  Modal,
} from "@cyberalarm/ui";
import { DetailsContext, DetailsStore } from "./context";
import { ModalDetails } from "./modal-details";
import { useDomainStatus, useDomainDetails } from "./hooks";
import {
  getFailedCount,
  getLastScanAt,
  getPassedCount,
  getWarningsCount,
  goodNews,
  title,
  allTests,
  printReport,
  helpMeFix,
} from "./schema";

import { selectors, StoreContext } from "store";

import { StatusCard, Results, TokensList, DomainProfile } from "ui";
import { getUserDomainTestsUrl, getUserDomainReportUrl } from "routes";
import themeGet from "@styled-system/theme-get";
import { pathOr } from "ramda";

import { CreateTicketModal } from "../support/create-ticket";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  color: ${themeGet("colors.black")};
  transition: 0.2s;
  :hover {
    text-decoration: underline;
  }
`;

const StyledLinkText = styled(Text)<{ isActive: boolean }>`
  text-decoration: none;
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  color: ${themeGet("colors.black")};
  transition: 0.2s;

  ${(p) =>
    p.isActive
      ? css`
          cursor: pointer;
          :hover {
            text-decoration: underline;
          }
        `
      : css`
          cursor: none;
          opacity: 0.5;
        `}
`;

const StyledHeaderText = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`;

const getFirstName = pathOr("", ["firstName"]);
const getLastName = pathOr("", ["lastName"]);

const DashboardComponent = memo(() => {
  const domain = useDomainDetails();
  const { open } = useContext(DetailsContext);
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const failedCount = getFailedCount(domain);
  const warningsCount = getWarningsCount(domain);
  const passedCount = getPassedCount(domain);
  const lastScanAt = getLastScanAt(domain);
  const { setUser, ...rest } = useContext(StoreContext);
  const user = selectors.getUser(rest);
  const firstName = useMemo(() => getFirstName(user), [user]);
  const lastName = useMemo(() => getLastName(user), [user]);

  const openTicketModal = () => {
    if (domain && domain.id) {
      setTicketModalOpen(true);
    }
  };

  const closeTicketModal = () => {
    setTicketModalOpen(false);
  };

  const activeStatus = useDomainStatus({
    failedCount,
    warningsCount,
    passedCount,
  });

  const hasProblems = failedCount > 0 || warningsCount > 0;
  const hasPassedTests = passedCount > 0;
  const isTestPassed = !hasProblems && hasPassedTests;

  return (
    <>
      <TitleBar label={`Hi ${firstName} ${lastName} !`} />
      <Box flexDirection={{ sm: "column", md: "column", lg: "row", xl: "row" }}>
        <Box
          flexDirection="column"
          width={{ sm: "100%", md: "100%", lg: "66%", xl: "66%" }}
          marginRight={{ sm: "0", md: "24px" }}
          display="block"
        >
          <StatusCard domain={domain} activeStatus={activeStatus} />

          <Card
            marginTop="24px"
            width="100%"
            minHeight="100px"
            p={0}
            paddingBottom="16px"
            height="fit-content"
            maxHeight={{ _: "400px", xl: "calc(90vh - 440px)" }}
          >
            <CardHeader minHeight="54px">
              <StyledHeaderText>
                <FormattedMessage
                  {...(isTestPassed ? goodNews : title)}
                  values={{ status: activeStatus && activeStatus }}
                />
              </StyledHeaderText>
              <FlexBox>
                <StyledLink to={getUserDomainReportUrl(domain && domain.id)}>
                  <Text>
                    <FormattedMessage {...printReport} />
                  </Text>
                </StyledLink>
                <StyledLink
                  to={getUserDomainTestsUrl(domain && domain.id)}
                  style={{ marginLeft: "16px" }}
                >
                  <Text>
                    <FormattedMessage {...allTests} />
                  </Text>
                </StyledLink>
                <StyledLinkText
                  isActive={failedCount + warningsCount > 0}
                  onClick={
                    failedCount + warningsCount > 0
                      ? openTicketModal
                      : // eslint-disable-next-line
                        () => "javascript:void(0)"
                  }
                  style={{ marginLeft: "16px" }}
                >
                  <FormattedMessage {...helpMeFix} />
                </StyledLinkText>
              </FlexBox>
            </CardHeader>
            <Results
              isTestPassed={isTestPassed}
              hasProblems={hasProblems}
              status={activeStatus}
              failedCount={failedCount}
              passedCount={passedCount}
              warningsCount={warningsCount}
              lastScanAt={lastScanAt}
              openDetails={open}
            />
          </Card>
        </Box>
        <Box
          flexDirection="column"
          width={{ sm: "100%", md: "100%", lg: "33%", xl: "33%" }}
          display="block"
          marginTop={["24px", "24px", "24px", "0"]}
          maxHeight={{
            _: "600px",
            xl: "calc(90vh - 100px)",
          }}
          justifyContent="space-between"
        >
          <Card width="100%" p={0} marginBottom="24px" flexDirection="column">
            <DomainProfile domain={domain} />
          </Card>
          {user?.planPreferences?.product === "full_protect" && (
            <Box display="block">
              <Card
                width="100%"
                minHeight="54px"
                p={0}
                paddingBottom="16px"
                height="fit-content"
                maxHeight={{ _: "400px", xl: "calc(27vh - 124px)" }}
                marginTop={{ sm: "24px", md: "24px", lg: "0", xl: "0" }}
              >
                <TokensList />
              </Card>
            </Box>
          )}
        </Box>

        <ModalDetails />
        <Modal isOpen={ticketModalOpen} close={closeTicketModal}>
          <CreateTicketModal close={closeTicketModal} domainID={domain?.id} />
        </Modal>
      </Box>
    </>
  );
});

DashboardComponent.displayName = "DashboardComponent";

export const Dashboard = memo(() => {
  return (
    <DetailsStore>
      <DashboardComponent />
    </DetailsStore>
  );
});

Dashboard.displayName = "Dashboard";
